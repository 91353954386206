import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import {
    CategoryDetailsComponent as SourceCategoryDetailsComponent,
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';
import Html from 'SourceComponent/Html';

import './CategoryDetails.style';

/** @namespace Inov8/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetailsComponent {
    renderCategoryDescription(): ReactElement {
        const {
            category: { description, id, cms_block },
            isCurrentCategoryLoaded,
        } = this.props;

        if (cms_block && cms_block.identifier) {
            return null;
        }

        const { isPrefetchValueUsed, actionName: { description: preloadDescription } } = window;

        if (isPrefetchValueUsed) {
            if (preloadDescription) {
                return <Html content={ preloadDescription } />;
            }

            return null;
        }

        if ((!id || !isCurrentCategoryLoaded)) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description) {
            return null;
        }

        return <Html content={ description } />;
    }

    renderCategoryName(): ReactElement {
        const {
            category: { name, id, cms_block },
        } = this.props;
        const {
            isPrefetchValueUsed,
        } = window;

        if (!id && !name && !isPrefetchValueUsed) {
            return null;
        }

        if (cms_block && cms_block.identifier) {
            return null;
        }

        return (
            <h1 block="CategoryDetails" elem="Heading">
                { this.renderCategoryText() }
            </h1>
        );
    }

    // TODO implement logic
    render(): ReactElement {
        return (
            <article block="CategoryDetails">
                <div block="CategoryDetails" elem="Description">
                    { this.renderCategoryName() }
                    { this.renderCategoryDescription() }
                </div>
                { /* { this.renderCategoryImage() } */ }
            </article>
        );
    }
}

export default CategoryDetailsComponent;
