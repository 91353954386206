import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import { RootState } from 'Util/Store/Store.type';

import {
    ProductListContainerProps,
} from './ProductList.type';
/** @namespace Inov8/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    // TODO implement logic
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
        RouteComponentProps & ProductListContainerProps
        >,
    ),
);
