/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Pagination as SourcePagination,
    ProductListComponent as SourceProductListComponent,
} from 'SourceComponent/ProductList/ProductList.component';

import { INTERSECTION_RATIO } from './ProductList.config';

// TODO: implement Pagination
export const Pagination = SourcePagination;

/** @namespace Inov8/Component/ProductList/Component */
export class ProductListComponent extends SourceProductListComponent {
    observePageChange(): void {
        const { updatePage } = this.props;
        this.pagesIntersecting = [];

        if (!this.observer && 'IntersectionObserver' in window) {
            const threshold = this._getThreshold();

            this.observer = new IntersectionObserver((entries) => {
                const { currentPage } = this.props;

                entries.forEach(({ target, isIntersecting, intersectionRatio }) => {
                    const page = +(Object.keys(this.nodes).find((node) => this.nodes[ node ] === target) || 0);
                    const index = this.pagesIntersecting.indexOf(page);

                    if (isIntersecting && intersectionRatio > INTERSECTION_RATIO && index === -1) {
                        this.pagesIntersecting.push(page);
                    }
                });

                const minPage = this.pagesIntersecting[this.pagesIntersecting.length - 1];

                if (minPage < Infinity && minPage !== currentPage) {
                    updatePage(minPage);
                }
            }, {
                rootMargin: '0px',
                threshold,
            });
        }

        this.updateObserver();
    }
}

export default ProductListComponent;
