/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { connect } from 'react-redux';

import { CategoryPageLayout, LOADING_TIME } from 'SourceRoute/CategoryPage/CategoryPage.config';
import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import { CategoryPageContainerProps } from 'SourceRoute/CategoryPage/CategoryPage.type';
import { debounce } from 'SourceUtil/Request/Debounce';
import { waitForPriorityLoad } from 'SourceUtil/Request/LowPriorityLoad';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Inov8/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    // TODO implement logic

    getDefaultPlpType(): CategoryPageLayout {
        const {
            defaultPlpType = CategoryPageLayout.LIST,
        } = this.state;

        return defaultPlpType;
    }

    componentDidUpdate(prevProps: CategoryPageContainerProps): void {
        const {
            isOffline,
            categoryIds,
            category: {
                id,
            },
        } = this.props;

        const {
            breadcrumbsWereUpdated,
        } = this.state;

        const {
            categoryIds: prevCategoryIds,
            category: {
                id: prevId,
            },
        } = prevProps;

        // TODO: category scrolls up when coming from PDP

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * If the URL rewrite has been changed, make sure the category ID
         * will persist in the history state.
         */
        if (categoryIds !== prevCategoryIds) {
            this.updateHistory();
        }

        /**
         * If the currently loaded category ID does not match the ID of
         * category from URL rewrite, request category.
         */
        if (categoryIds !== id) {
            waitForPriorityLoad().then(
            /** @namespace Route/CategoryPage/Container/CategoryPageContainer/componentDidUpdate/waitForPriorityLoad/then */
                () => this.requestCategory(),
            );
        }

        /**
         * If category ID was changed => it is loaded => we need to
         * update category specific information, i.e. breadcrumbs.
         *
         * Or if the breadcrumbs were not yet updated after category request,
         * and the category ID expected to load was loaded, update data.
         */
        const categoryChange = id !== prevId || (!breadcrumbsWereUpdated && id === categoryIds);

        if (categoryChange) {
            this.checkIsActive();
            this.updateBreadcrumbs();
            this.updateHeaderState();
        }
        this.updateMeta();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    CategoryPageContainer,
);
