/* eslint-disable react/forbid-dom-props */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/require-default-props */
// src/component/PLPBanner/PLPBanner.component.js
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'SourceComponent/Link';
import { getStoreState } from 'SourceUtil/Store';

import './PLPBanner.style.scss';

/** @namespace Inov8/Component/PLPBanner/Component */
export class PLPBannerComponent extends PureComponent {
    static propTypes = {
        listView: PropTypes.bool.isRequired,
        bannerData: PropTypes.shape({
            grid_desktop_image_path: PropTypes.string,
            grid_tablet_image_path: PropTypes.string,
            grid_mobile_image_path: PropTypes.string,
            list_desktop_image_path: PropTypes.string,
            list_tablet_image_path: PropTypes.string,
            list_mobile_image_path: PropTypes.string,
            link: PropTypes.string,
        }),
    };

    getImagePath() {
        const { listView, bannerData } = this.props;
        const screenWidth = window.innerWidth;

        if (listView) {
            if (screenWidth >= 1024) {
                return bannerData.list_desktop_image_path;
            }

            if (screenWidth >= 768) {
                return bannerData.list_tablet_image_path;
            }

            return bannerData.list_mobile_image_path;
        }

        if (screenWidth >= 1024) {
            return bannerData.grid_desktop_image_path;
        }

        if (screenWidth >= 768) {
            return bannerData.grid_tablet_image_path;
        }

        return bannerData.grid_mobile_image_path;
    }

    render() {
        const { bannerData, listView } = this.props;
        const { ConfigReducer } = getStoreState();

        if (!bannerData) {
            return null;
        }

        if (!ConfigReducer) {
            return null;
        }

        const imagePath = this.getImagePath();

        if (!bannerData.link) {
            return (
                <li>
                    <div className={ `banner-component ${listView ? 'list-view' : 'grid-view'}` }>
                        <img src={ `/media/inov8_plpbanners/${imagePath}` } alt="PLP Banner" loading="lazy" />
                    </div>
                </li>
            );
        }

        return (
            <li className="banner-li">
                <div className={ `banner-component ${listView ? 'list-view' : 'grid-view'}` }>
                    <Link to={ ConfigReducer.base_link_url + bannerData.link }>
                        <img src={ `/media/inov8_plpbanners/${imagePath}` } alt="PLP Banner" loading="lazy" />
                    </Link>
                </div>
            </li>
        );
    }
}

export default PLPBannerComponent;
