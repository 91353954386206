/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
// ProductListPage.component.tsx
import React from 'react';
import CategoryContext from 'src/context/CategoryContext';

import PLPBannerContainer from 'Component/PLPBanner/PLPBanner.container';
import ProductCard from 'Component/ProductCard';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPageComponent as SourceProductListPageComponent,
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import { ReactElement } from 'Type/Common.type';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import './ProductListPage.style.scss';

/** @namespace Inov8/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPageComponent {
    startObserving(): void {
        const {
            items,
            updatePages,
            isInfiniteLoaderEnabled,
        } = this.props;

        if (!isInfiniteLoaderEnabled || items.length) {
            return;
        }

        if (this.node && !this.observer && 'IntersectionObserver' in window) {
            const options = {
                rootMargin: '0px',
                threshold: 0.1,
            };

            this.observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                const { items, isLoading } = this.props;

                // must not be a product items list, and must not be loading
                if (intersectionRatio > 0 && !items.length && !isLoading) {
                    this.stopObserving();
                    updatePages();
                }
            }, options);

            this.observer.observe(this.node);
        }
    }

    renderPageItems(): ReactElement {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
        } = this.props;

        const isListView = layout === CategoryPageLayout.LIST;

        return (
            <CategoryContext.Consumer>
                { (category) => (
                    items.map((product, index) => {
                        const productCard = (
                            <ProductCard
                              product={ product }
                              key={ `ProductCard-${product.id}` }
                              selectedFilters={ selectedFilters }
                              layout={ layout as CategoryPageLayout }
                              { ...this.containerProps() }
                              onLoad={ setLoadedFlag }
                              isGendered={ category.is_gendered }
                              gender={ category.gender }
                            />
                        );

                        const adjustedPosition = index + 1;

                        return (
                            <React.Fragment key={ `Fragment-${product.id}` }>
                            { adjustedPosition >= 0 && category ? ( // Check if category and category.id are valid
                                <PLPBannerContainer
                                  listView={ isListView }
                                  currentPosition={ adjustedPosition }
                                  banner={ category.PlpBanner }
                                />
                            ) : null }
                            { productCard }
                            </React.Fragment>
                        );
                    })
                ) }
            </CategoryContext.Consumer>
        );
    }
}

export default ProductListPageComponent;
