import { Field } from '@tilework/opus';

/** @namespace Inov8/Query/PlpBanner/Query */
export class PlpBannerQuery {
    getBannerImages() {
        return new Field('PlpBanner')
            .addFieldList([
                'grid_desktop_image_path',
                'grid_tablet_image_path',
                'grid_mobile_image_path',
                'list_desktop_image_path',
                'list_tablet_image_path',
                'list_mobile_image_path',
                'display_position',
                'link',
            ]);
    }
}

export default new PlpBannerQuery();
