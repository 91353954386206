import PlpBannerQuery from '../query/PlpBanner.query';

const AddPlpBannerToCategoryInterface = (args, callback) => [
    ...callback(...args),
    PlpBannerQuery.getBannerImages(),
];

export default {
    'Query/Category/Query': {
        'member-function': {
            _getDefaultFields: AddPlpBannerToCategoryInterface,
        },
    },
};
