/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-nested-ternary */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
import CategoryProductList from '@scandipwa/scandipwa/src/component/CategoryProductList';
import CmsBlock from '@scandipwa/scandipwa/src/component/CmsBlock';
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import Loader from '@scandipwa/scandipwa/src/component/Loader';
import { CategoryPageLayout } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { isCrawler, isSSR } from '@scandipwa/scandipwa/src/util/Browser';
import { setLoadedFlag } from '@scandipwa/scandipwa/src/util/Request/LowPriorityLoad';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import CategoryContext from 'src/context/CategoryContext';

import {
    CategoryFilterOverlay as SourceCategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort as SourceCategorySort,
    FilterIcon as SourceFilterIcon,
    GridIcon as SourceGridIcon,
    ListIcon as SourceListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';

import './CategoryPage.style';
// TODO: implement CategoryFilterOverlay
export const CategoryFilterOverlay = SourceCategoryFilterOverlay;

// TODO: implement FilterIcon
export const FilterIcon = SourceFilterIcon;

// TODO: implement GridIcon
export const GridIcon = SourceGridIcon;

// TODO: implement ListIcon
export const ListIcon = SourceListIcon;

// TODO: implement CategorySort
export const CategorySort = SourceCategorySort;

/** @namespace Inov8/Route/CategoryPage/Component */
// @ts-ignore
export class CategoryPageComponent extends SourceCategoryPageComponent {
    showHideFilters = false;

    __construct() {
        this.state = {
            activeLayoutType: window.innerWidth < 800 ? CategoryPageLayout.LIST : CategoryPageLayout.GRID,
            lastViewportWidth: window.innerWidth,
        };
    }

    componentDidMount() {
        this.setActiveLayoutTypeBasedOnScreenWidth();
        window.addEventListener('resize', this.setActiveLayoutTypeBasedOnScreenWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setActiveLayoutTypeBasedOnScreenWidth);
    }

    componentDidUpdate(prevProps:any) {
        if (this.props.selectedLayoutType !== prevProps.selectedLayoutType) {
            this.setState({ activeLayoutType: this.props.selectedLayoutType });
        }
    }

    setActiveLayoutTypeBasedOnScreenWidth = () => {
        const currentViewportWidth = window.innerWidth;
        const { lastViewportWidth } = this.state;

        if (currentViewportWidth !== lastViewportWidth) {
            const layoutType = currentViewportWidth < 800 ? CategoryPageLayout.LIST : CategoryPageLayout.GRID;

            this.setState({
                activeLayoutType: layoutType,
                lastViewportWidth: currentViewportWidth,
            });
        }
    };

    hideCategoryFilterOverlay = () => {
        const categoryFilterOverlay = document.querySelector('.FilterSortOrder');

        if (categoryFilterOverlay) {
            categoryFilterOverlay.classList.toggle('hidden');
        }
        this.showHideFilters = !this.showHideFilters;
    };

    onGridButtonClick = () => {
        this.setState({ activeLayoutType: CategoryPageLayout.GRID });
    };

    onListButtonClick = () => {
        this.setState({ activeLayoutType: CategoryPageLayout.LIST });
    };

    static getDerivedStateFromProps(props:any, state:any) {
        // Use a state property to track the previous prop value
        const hasSelectedLayoutChanged = props.selectedLayoutType !== state.prevSelectedLayoutType;

        return {
            // Update the active layout type if it has changed
            activeLayoutType: hasSelectedLayoutChanged ? props.selectedLayoutType : state.activeLayoutType,
            // Update the prevSelectedLayoutType for the next comparison
            prevSelectedLayoutType: props.selectedLayoutType,
        };
    }

    renderFilterPlaceholder(): ReactElement {
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper">
                <div block="CategoryPage" elem="PlaceholderContainer">
                    <h3 block="CategoryPage" elem="PlaceholderHeading">
                        { __('') }
                    </h3>
                    <div block="CategoryPage" elem="PlaceholderList">
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                    </div>
                    <Loader isLoading />
                </div>
            </div>
        );
    }

    renderCategoryProductList(): ReactElement {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { /* { this.renderItemsCount(false) } */ }
                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType }
                />
            </div>
        );
    }

    renderFilterButton(): ReactElement {
        const {
            isContentFiltered,
            totalPages,
            category: { is_anchor },
            isSearchPage,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            onFilterButtonClick,
            isMobile,
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if ((!isContentFiltered && totalPages === 0) || (!is_anchor && !isSearchPage) || !isCurrentCategoryLoaded) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              //  elem="Filter"
              elem={ this.showHideFilters && !isMobile ? ('Filter buttonActive') : ('Filter') }
              onClick={ () => {
                  onFilterButtonClick();
                  this.hideCategoryFilterOverlay();
              } }
            >
                <Suspense fallback={ <div block="CategoryPage" elem="FilterPlaceholder" /> }>
                    <FilterIcon
                      block="CategoryPage"
                      elem="FilterIcon"
                      isActive={ this.hideCategoryFilterOverlay }
                    />
                </Suspense>
                { /* <span>{ __('Filters') }</span> */ }

                { isMobile ? (
                    <span>Filter & Sort</span>
                ) : (
                    this.showHideFilters ? (
                        <span>Hide Filter & Sort</span>
                    ) : (
                        <span>Show Filter & Sort</span>
                    )
                ) }

            </button>
        );
    }

    renderFilterAndSortHeading(): ReactElement {
        return (
            <h2 block="CategoryFilterOverlay" elem="Heading">
                { __('Filter & Sort') }
            </h2>
        );
    }

    renderFilterOverlay(): ReactElement {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            isMobile,
        } = this.props;

        const { category: { is_anchor } } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        const modalRoot = document.querySelector('.CategoryFilterOverlay-ResetSection') as HTMLElement;

        if (isMobile) {
            return (
                <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                    <CategoryFilterOverlay
                      availableFilters={ filters }
                      customFiltersValues={ selectedFilters }
                      isMatchingInfoFilter={ isMatchingInfoFilter }
                      isCategoryAnchor={ !!is_anchor }
                      isSearchPage={ isSearchPage }
                      renderPlaceholder={ this.renderPlaceholder }
                    />
                    { modalRoot ? (
                        ReactDOM.createPortal(this.renderCategorySort(), modalRoot)
                    ) : null }
                </Suspense>
            );
        }

        return (
            <div block="FilterSortOrder hidden">
                { this.renderFilterAndSortHeading() }
                <Suspense fallback={ this.renderFilterPlaceholder() || null }>
                { this.renderCategorySort() }
                    <CategoryFilterOverlay
                      availableFilters={ filters }
                      customFiltersValues={ selectedFilters }
                      isMatchingInfoFilter={ isMatchingInfoFilter }
                      isCategoryAnchor={ !!is_anchor }
                      isSearchPage={ isSearchPage }
                      renderPlaceholder={ this.renderPlaceholder }
                      hideCategoryFilterOverlay={ this.hideCategoryFilterOverlay }
                    />
                </Suspense>
            </div>
        );
    }

    renderLayoutButton(type: CategoryPageLayout): ReactElement {
        const { activeLayoutType } = this.state;

        switch (type) {
        case CategoryPageLayout.GRID:
            return (
                <button
                  block="LayoutButton"
                  key={ type }
                  onClick={ () => this.setState({ activeLayoutType: CategoryPageLayout.GRID }) }
                  mix={ {
                      block: CategoryPageLayout.GRID,
                      mods: { isActive: activeLayoutType === CategoryPageLayout.GRID },
                  } }
                  aria-label="grid"
                >
                    <div block="skeleton-loading">
                        <div block="LayoutButtonLabel">
                            <span>Grid</span>
                        </div>
                        <GridIcon isActive={ activeLayoutType === CategoryPageLayout.GRID } />
                    </div>
                </button>
            );
        case CategoryPageLayout.LIST:
            return (
                <button
                  block="LayoutButton"
                  key={ type }
                  onClick={ () => this.setState({ activeLayoutType: CategoryPageLayout.LIST }) }
                  mix={ {
                      block: CategoryPageLayout.LIST,
                      mods: { isActive: activeLayoutType === CategoryPageLayout.LIST },
                  } }
                  aria-label="list"
                >
                    <div block="skeleton-loading">
                        <div block="LayoutButtonLabel">
                            <span>List</span>
                        </div>
                        <ListIcon isActive={ activeLayoutType === CategoryPageLayout.LIST } />
                    </div>
                </button>
            );
        default:
            return false;
        }
    }

    renderMiscellaneous(): ReactElement {
        const { totalItems } = this.props;

        if (totalItems === 0 || !this.displayProducts()) {
            return <div block="CategoryPage" elem="Miscellaneous" mods={ { noResults: true } } />;
        }

        return (
        <div block="CategoryPage" elem="Miscellaneous">
            <div
              block="CategoryPage"
              elem="MiscellaneousLayoutWrapper"
            >
                <div
                  block="CategoryPage"
                  elem="LayoutWrapper-Filter"
                  mods={ { isPrerendered: isSSR() || isCrawler() } }
                >
                    { this.renderFilterButton() }
                </div>
                <div
                  block="CategoryPage"
                  elem="LayoutWrapper"
                  mods={ { isPrerendered: isSSR() || isCrawler() } }
                >
                    { this.renderLayoutButtons() }

                </div>
            </div>
        </div>
        );
    }

    renderTopRowContent(): ReactElement {
        return (
            <>
            { this.renderCategoryDetails() }
            <ContentWrapper>
            { this.renderMiscellaneous() }
            </ContentWrapper>
            </>
        );
    }

    renderContent(): ReactElement {
        return (
            <>
                { this.renderFilterOverlay() }
                { this.renderCategoryProductList() }
            </>
        );
    }

    renderCmsBlock(): ReactElement {
        const { category: { cms_block }, isCurrentCategoryLoaded } = this.props;

        if (!cms_block || !this.displayCmsBlock() || !isCurrentCategoryLoaded) {
            return null;
        }

        const { disabled, identifier } = cms_block;

        if (disabled) {
            return null;
        }

        return (
            <CmsBlock identifier={ identifier } />
        );
    }

    render(): ReactElement {
        const hideProducts = !this.displayProducts();
        const { totalItems } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                { this.renderCmsBlock() }
                { this.renderTopRowContent() }
                <CategoryContext.Provider value={ this.props.category }>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
                </CategoryContext.Provider>
            </main>
        );
    }
}

export default CategoryPageComponent;
