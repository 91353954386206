import CategoryGenderQuery from '../query/CategoryGender.query';

const AddGenderToCategoryInterface = (args, callback) => [
    ...callback(...args),
    CategoryGenderQuery.getIsGender(),
    CategoryGenderQuery.getGender(),
];

export default {
    'Query/Category/Query': {
        'member-function': {
            _getDefaultFields: AddGenderToCategoryInterface,
        },
    },
};
