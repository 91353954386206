import { Field } from '@tilework/opus';

/** @namespace Inov8/Query/CategoryGender/Query */
export class CategoryGenderQuery {
    getGender() {
        return new Field('gender');
    }

    getIsGender() {
        return new Field('is_gendered');
    }
}

export default new CategoryGenderQuery();
