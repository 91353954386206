/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import DataContainer from 'SourceUtil/Request/DataContainer';

import PLPBannerComponent from './PLPBanner.component';

/** @namespace Inov8/Component/PLPBanner/Container */
export class PLPBannerContainer extends DataContainer {
    static propTypes = {
        listView: PropTypes.bool.isRequired,
        currentPosition: PropTypes.number.isRequired,
        banner: PropTypes.object,
    };

    render() {
        const { listView, banner, currentPosition } = this.props;

        if (!banner) {
            return null;
        }

        if (currentPosition !== banner.display_position) {
            return null;
        }

        return (
            <PLPBannerComponent
              listView={ listView }
              bannerData={ banner }
            />
        );
    }
}

export default PLPBannerContainer;
